/* Bosch Icons  */
@font-face {
    font-family: "bosch-icon";
    font-weight: normal;
    font-style: normal;
    src: url('../icons/Bosch-Icon.eot?rgv8gt');
    src: url('../icons/Bosch-Icon.eot?rgv8gt#iefix') format('embedded-opentype'),
        url('../icons/Bosch-Icon.ttf') format('truetype'),
        url('../icons/Bosch-Icon.woff') format('woff'),
        url('../icons/Bosch-Icon.woff2') format('woff2'),
        url('../icons/Bosch-Icon.svg') format('svg');
}

/* Bosch UX Icons  */
@font-face {
    font-family: "ux-icon";
    font-weight: normal;
    font-style: normal;
    src: url('../icons/ux-icons.eot'),
        url('../icons/ux-icons.ttf') format('truetype'),
        url('../icons/ux-icons.woff') format('woff'),
        url('../icons/ux-icons.svg') format('svg');
}

[class*=" bosch-icon-"]:before {
    font-family: 'bosch-icon';
    speak: none;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
}

[class*=" ux-icon-"]:before {
    font-family: 'ux-icon';
    speak: none;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
}

.rbicon {
    font-size: 18px;
    font-style: normal;
}

.bosch-icon-user::before {
    content: '\e29b';
}

.ux-icon-users::before {
    content: '\e902';
}

.bosch-icon-users > * {
    fill: white;
}

.bosch-icon-arrowDown::before {
    content: '\e147';
}

.bosch-icon-viewMobile::before {
    content: '\e1c1';
}

.bosch-icon-tune::before {
    content: '\e291';
}

.bosch-icon-boxClosed::before {
    content: '\e0bc';
}

.ux-icon-boxes::before {
    content: '\e901';
}

.bosch-icon-userHand::before {
    content: '\e2a1';
}

.bosch-icon-securityUser::before {
    content: '\e238';
}

.bosch-icon-server::before {
    content: '\e23a';
}

.bosch-icon-lockClosed::before {
    content: '\e1c8';
}

.bosch-icon-clock::before {
    content: '\e0ef';
}

.ux-icon-calendarClock::before {
    content: '\e900';
}

.bosch-icon-import::before {
    content: '\e1a5';
}

.bosch-icon-export::before {
    content: '\e166';
}

.bosch-icon-forwardRight::before {
    content: '\e181';
}

.bosch-icon-down::before {
    content: '\e147';
}

.bosch-icon-alertFilled::before {
    content: '\eb08';
}

.bosch-icon-successFilled::before {
    content: '\eb0a';
}

.bosch-icon-warningFilled::before {
    content: '\eb0c';
}

.bosch-icon-search::before {
    content: '\e235';
}

.bosch-icon-alertEmpty::before {
    content: '\eb09';
}

.bosch-icon-successEmpty::before {
    content: '\eb0b';
}

.bosch-icon-informationEmpty::before {
    content: '\e1ac';
}

/*******************************************************************************
                            Custom Styling
*******************************************************************************/
/* CIAMAUTHZ-5669 - External Link icon for Anchor (a) elements */
a.a-icon-externalLink:after {
    content: '\e167';
    font-family: 'Bosch-Icon';
    margin-left: 4px;
    display: inline-block;
    color: #007BC0;
}

/* CIAMAUTHZ-5669 - Info icon for Anchor (a) elements*/
a.a-icon-info:after {
    content: '\eb0e';
    font-family: 'Bosch-Icon';
    margin-left: 4px;
    display: inline-block;
    color: #007BC0;
}

/* CIAMAUTHZ-5669 - Alert/Warning icon for Anchor (a) elements*/
a.a-icon-alert:after,
/* CIAMAUTHZ-5669 - Warning/Alert icon for footer links when href="https://example.com/*" used in Anchor (a) elements*/
footer a[href*="https://example.com/"]:after {
    content: '\eb0c';
    font-family: 'Bosch-Icon';
    margin-left: 4px;
    display: inline-block;
    color: #FFCF00;
}
